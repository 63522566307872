import React, { useState, useEffect } from "react";
import cheerio from "cheerio";
import axios from "axios";

export default function App() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      <h1 style={{ marginBottom: "10px", color: "black" }}>Zuck Daily</h1>
      <p style={{ fontSize: "14px", marginTop: 0, marginBottom: 40 }}>
        A picture of Mark Zuckerberg every day. That's it.
      </p>
      <div>
        <img
          src={
            "https://i.pinimg.com/originals/ac/88/a0/ac88a00e247bb2383da6e3633b263c56.jpg"
          }
          alt="Mark Zuckerberg"
          style={{
            width: "400px",
            height: "400px",
            objectFit: "cover",
          }}
        />
        <p style={{ fontSize: "12px", marginTop: 40 }}>
          Made with ❤️ and ☕ by Bardia Safari.
        </p>
      </div>
    </div>
  );
}
